import { useAppState } from '@src/app.state'
import { isEmpty } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  determineNextIteration,
  findLastEditedSection,
} from '../activity-utils'
import { getNavItems } from '../nav-items'
import { useActivityStore } from '../state/use-activity-state'

type Direction = 1 | -1 | 0

export function useNavigationEffect() {
  const { sectionId, iterationId, orgId } = useParams()
  const user = useAppState(state => state.user)
  const notifications = useAppState(state => state.notifications)
  const navigate = useNavigate()
  const location = useLocation()
  const activityUser = useActivityStore(state => state.activityUser)
  const progressSections = useActivityStore(state => state.progressSections)
  const activitySaving = useActivityStore(state => state.activitySaving)
  const iterations = useActivityStore(state => state.iterations)
  const sections = useActivityStore(state => state.sections)
  const [direction, setDirection] = useState<Direction>(0)

  const fromRef = useRef(null)

  const navItems = useMemo(() => {
    const activity = useActivityStore.getState().activity
    return getNavItems(activity, user, activityUser, notifications)
  }, [user, activityUser, notifications])

  useEffect(() => {
    if (activityUser?.deactivated) {
      const firstWorkspace = user.orgOrderIds[0]
      navigate(`/${firstWorkspace}`)
    }
  }, [activityUser?.deactivated])

  useEffect(() => {
    if (location.state) {
      fromRef.current = location.state.from
    }
  }, [])

  useEffect(() => {
    const matchingPaths = navItems.filter(({ to }) =>
      location.pathname.includes(to),
    ).length
    const pathContainsNonSection = matchingPaths > 0
    if (pathContainsNonSection) return

    if (!isEmpty(sections)) {
      if (!activityUser) return
      if (activitySaving || sectionId) return
      const activity = useActivityStore.getState().activity

      if (activity?.isSequential) {
        // If the activity is sequential, go to the active section
        const currentSection = sections[activityUser.activeSectionIndex]

        if (currentSection) {
          navigate(currentSection.id)
        } else {
          navigate(sections[0].id)
        }
      } else {
        // If the activity is not sequential, go to the last edited section
        const lastEditedSection = findLastEditedSection(progressSections)

        if (lastEditedSection) {
          navigate(lastEditedSection.sectionId)
        } else {
          navigate(sections[0].id)
        }
      }
    }
  }, [progressSections, sections, activityUser])

  useEffect(() => {
    if (activitySaving) return
    if (!isEmpty(iterations) && sectionId) {
      const iteration = determineNextIteration(iterations, sectionId)
      if (iteration) {
        navigate(`${sectionId}/${iteration.id}`)
      }
    }
  }, [iterations, sectionId])

  const findIterationIndex = (id: string) =>
    iterations.findIndex(i => i.id === id)

  const paginate = (
    newIteration: SectionIteration,
    newDirection: Direction,
  ) => {
    if (!newIteration) return
    setDirection(newDirection)
    navigate(`${sectionId}/${newIteration.id}`)
  }

  const goToNextIteration = () => {
    const currentIterationIndex = findIterationIndex(iterationId)
    const nextIteration = iterations[currentIterationIndex + 1]
    paginate(nextIteration, 1)
  }

  const goToPreviousIteration = () => {
    const currentIterationIndex = findIterationIndex(iterationId)
    const previousIteration = iterations[currentIterationIndex - 1]
    paginate(previousIteration, -1)
  }

  const exitActivity = () => {
    if (fromRef.current) {
      navigate(fromRef.current)
      return
    }

    if (location.pathname.includes('home')) {
      navigate(`/${orgId}/home`)
    } else if (location.pathname.includes('mentoring')) {
      navigate(`/${orgId}/mentoring`)
    } else {
      navigate(-1)
    }
  }

  return {
    exitActivity,
    paginate,
    goToNextIteration,
    goToPreviousIteration,
    direction,
    iterations: iterations,
    navItems,
  }
}
