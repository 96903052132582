import { db } from '@src/firebase-app'
import {
  calcSectionCompletionPercentage,
  getActivityCompletionPercentage,
  getTotalIterationCount,
} from '@src/pages/workflow/client/workflow-refactor/activity-utils'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { modalService } from '@utils/modal.service'
import { toastService } from '@utils/toast.service'
import {
  collection,
  doc,
  getDocs,
  increment,
  query,
  runTransaction,
  where,
} from 'firebase/firestore'
import { intersection, isEmpty, isEqual } from 'lodash'
import { useMemo, useState } from 'react'
import { ulid } from 'ulid'
import {
  calculateIterationCompletion,
  compareFieldIds,
  compareSections,
  getActivityData,
  getAddedSections,
  getConstraintDifferences,
  sanitizeSections,
  openVerifyFieldsVersionBumpModal,
} from './publish-utils'
import { StartCourseModal } from './start-course-modal.component'

export type Props = {
  orgId: string
  template: Template
  sections: ActivitySection[]
  previousSections: ActivitySection[]
  onCreateActivity?: () => void
  onPublishTemplate?: (newSections?: ActivitySection[]) => void
}

export function useActivityPublisher({
  orgId,
  template,
  sections,
  previousSections,
  onCreateActivity = () => {},
  onPublishTemplate = () => {},
}: Props) {
  const [publishing, setPublishing] = useState(false)

  const fieldsToDisregard = ['version']

  const noChangesMade = useMemo(() => {
    if (isEmpty(previousSections) || isEmpty(sections) || !template) return true

    const sanitizedPreviousSections = sanitizeSections(
      previousSections,
      fieldsToDisregard,
    )
    const sanitizedSections = sanitizeSections(sections, fieldsToDisregard)

    return isEqual(sanitizedPreviousSections, sanitizedSections)
  }, [sections, previousSections, template])

  if (!orgId) {
    throw new Error('orgId is required')
  }

  if (!template) {
    return {}
  }

  const sectionsRef = doc(
    db,
    `${dbNames.workflowTemplates}/${template.id}/sections/${template.version}`,
  )

  async function publishActivity() {
    try {
      if (template.version === 0) {
        await createActivity()
        onPublishTemplate()
      } else {
        const newSections = await updateActivity()
        if (newSections) {
          onPublishTemplate(newSections as ActivitySection[])
        }
      }
    } catch (err) {
      // Do nothing on error
    }
  }

  async function createActivity() {
    setPublishing(true)
    try {
      await modalService.render(StartCourseModal, {
        orgId,
        template,
        sections,
      })
      onCreateActivity()
      setPublishing(false)
    } catch (err) {
      console.log(err)
    }
  }

  async function updateActivity() {
    // Sorry for this function...there is a lot that needs to happen for this update to take place,
    // so the only way to clean this up is to break it into smaller functions.
    // I tested the util functions fairly well, but the hook itself is difficult to test properly.

    setPublishing(true)
    let newSections = sections

    // Compare the old sections with the new sections to determine what has changed on the section level
    const { addedSections, sameSections, removedSections, sameSectionsOld } =
      getAddedSections(sections, previousSections)

    // Compare the old sections with the new sections to determine what has changed on the field level
    const { addedFields, removedFields } = compareFieldIds(
      sameSections,
      sameSectionsOld,
    )

    // Figure out which fields need a version bump (fields that have had large changes).
    // Fields that need a version bump will reset the user's response to that field.
    // The response is still stored in the database associated with the old field version.
    let versionBumpFields = await compareSections(sameSections, sameSectionsOld)
    let fieldsNeedVersionBump = !isEmpty(versionBumpFields)

    // Compare the old sections with the new sections to determine what has changed on the constraint level (required fields)
    const sectionsWithRequirementChanges = getConstraintDifferences(
      sameSections,
      sameSectionsOld,
    )

    const activitiesSnap = await getDocs(
      query(
        collection(db, dbNames.activities),
        where('templateId', '==', template.id),
      ),
    )

    if (fieldsNeedVersionBump) {
      // If fields need a version bump, open a modal to let the admin know that the user's responses will be reset,
      // and give the admin a chance to override that decision. We do this because the decision maker for the version bump is chatGPT.
      // It works really well, but in case its ever wrong we want to give the admin a chance to override it.
      const {resolveType, selectedVersionBumpFields} = await openVerifyFieldsVersionBumpModal(
        versionBumpFields,
        sameSectionsOld,
        sections,
      )

      // If the admin cancels from the modal, stop the publishing process
      if (resolveType === 'reject') {
        setPublishing(false)
        return
      }

      // If the admin approves the version bump, only keep the fields that the admin decided need a version bump
      versionBumpFields = Object.keys(selectedVersionBumpFields).reduce(
        (accum, sectionId) => {
          if (selectedVersionBumpFields[sectionId].length === 0) {
            return accum
          }

          return {
            ...accum,
            [sectionId]: selectedVersionBumpFields[sectionId],
          }
        },
        [],
      )
      fieldsNeedVersionBump = !isEmpty(versionBumpFields)
    }

    const fieldsWereAddedOrRemoved = !isEmpty(
      Object.values({
        ...addedFields,
        ...removedFields,
      }).reduce((accum: Field[], field: Field[]) => [...accum, field], []),
    )

    // Request all the progress sections and activity users for the activities that use this template
    const { activityUsersMap, progressSectionsMap } = await getActivityData(
      fieldsNeedVersionBump ||
        fieldsWereAddedOrRemoved ||
        !isEmpty(addedSections) ||
        !isEmpty(removedSections) ||
        !isEmpty(sectionsWithRequirementChanges),
      activitiesSnap,
      sections.map(section => section.id),
    )

    try {
      // Do all the updates necessary in a transaction (if one fails, they all fail)
      await runTransaction(db, async transaction => {
        for (const activityDoc of activitiesSnap.docs) {
          const activity = activityDoc.data()

          // Update the activity version
          transaction.update(activityDoc.ref, {
            version: increment(1),
          })

          if (addedSections.length > 0 || removedSections.length > 0) {
            // Update the data for each participant in the activity
            for (const activityUserDoc of activityUsersMap[activityDoc.id]) {
              const { userId, activeSectionIndex } = activityUserDoc.data()
              let incrementCount = 0

              for (const addedSection of addedSections) {
                // If there are added sections, then each user needs to have a new progressSection added
                const progressSectionId = `${activityDoc.id}_${userId}_${addedSection.id}`

                const addedSectionIndex = sections.findIndex(
                  section => addedSection.id === section.id,
                )

                if (addedSectionIndex <= activeSectionIndex) {
                  incrementCount++
                }

                const timeZone =
                  Intl.DateTimeFormat().resolvedOptions().timeZone
                const iterationId = ulid()
                const progressSectionDocRef = doc(
                  db,
                  dbNames.progressSections,
                  progressSectionId,
                )
                const iterationDocRef = doc(
                  db,
                  dbNames.sectionIterations,
                  iterationId,
                )

                const progressSectionPayload: Omit<ProgressSection, 'id'> = {
                  userId,
                  sectionId: addedSection.id,
                  activityId: activityDoc.id,
                  lastEditedDate: null,
                  completedDate: null,
                  completedFieldsMap: {},
                  completedPercentage: 0,
                  completedIterationMap: {},
                  iterationOrderIds: [iterationId],
                  isSubmitted: false,
                }

                transaction.set(progressSectionDocRef, progressSectionPayload)

                transaction.set(iterationDocRef, {
                  activityId: activityDoc.id,
                  completedDate: null,
                  createdDate: getTodayDate().toISO(),
                  startDate: {
                    date: getTodayDate().toISO({ includeOffset: false }),
                    timeZone,
                  },
                  sectionId: addedSection.id,
                  iterationId,
                  userId,
                  fieldResponses: {},
                })

                progressSectionsMap[activityDoc.id].push({
                  ...progressSectionPayload,
                  id: progressSectionId,
                })
              }

              for (const removedSection of removedSections) {
                // If there are removed sections, then each user needs to have their associated progressSection removed
                progressSectionsMap[activityDoc.id] = progressSectionsMap[
                  activityDoc.id
                ].filter(
                  progressSection =>
                    progressSection.sectionId !== removedSection.id,
                )
              }

              transaction.update(activityUserDoc.ref, {
                unlockDate: null,
              })
            }
          }

          if (
            !isEmpty(versionBumpFields) ||
            !isEmpty(addedFields) ||
            !isEmpty(removedFields) ||
            !isEmpty(sectionsWithRequirementChanges)
          ) {
            // Provided there are fields that have changed, continue with calculating the new progress

            // Get the affected progressSections for this activity (those that have fields that have changed)
            const affectedProgressSections = progressSectionsMap[
              activityDoc.id
            ].filter(progressSection => {
              const sectionIdsAffected = [
                ...Object.keys(versionBumpFields),
                ...Object.keys(addedFields),
                ...Object.keys(removedFields),
                ...Object.keys(sectionsWithRequirementChanges),
              ]
              return sectionIdsAffected.includes(progressSection.sectionId)
            })

            affectedProgressSections.forEach(progressSection => {
              const { sectionId, completedFieldsMap } = progressSection
              if (
                isEmpty(addedFields[sectionId]) &&
                isEmpty(removedFields[sectionId]) &&
                isEmpty(versionBumpFields[sectionId]) &&
                isEmpty(sectionsWithRequirementChanges[sectionId])
              ) {
                return
              }

              // Carry out the necessary updates for each affected progressSection
              const section = sections.find(
                section => section.id === progressSection.sectionId,
              )

              const newRequiredFields = section.fields
                .filter(field =>
                  section.constraints.required.includes(field.id),
                )
                .map(field => field.id)

              const completedFieldIds =
                Object.keys(completedFieldsMap).length > 0
                  ? completedFieldsMap[
                      Object.keys(completedFieldsMap)[0]
                    ].filter(fieldId => {
                      return !versionBumpFields[sectionId]?.includes(
                        fieldId.split('_')[1],
                      )
                    })
                  : []

              const _completedIterationMap: Record<string, number> = {}
              Object.keys(progressSection.completedIterationMap).forEach(
                iterationId => {
                  _completedIterationMap[iterationId] =
                    calculateIterationCompletion(
                      newRequiredFields,
                      completedFieldIds,
                      section.fields,
                    )
                },
              )

              const progressSectionRef = doc(
                db,
                dbNames.progressSections,
                progressSection.id,
              )

              const totalIterationCount = getTotalIterationCount(
                section.constraints,
                activity.startDate,
                activity.endDate,
              )
              const newSectionPercent = calcSectionCompletionPercentage(
                _completedIterationMap,
                totalIterationCount,
              )

              const fieldsThatAffectSubmittedStatus = [
                ...(addedFields[sectionId] || []).map(f => f.id),
                ...(versionBumpFields[sectionId] || []),
              ]

              const progressSectionPayload = {
                completedIterationMap: _completedIterationMap,
                completedPercentage: newSectionPercent,
                completedDate:
                  newSectionPercent === 100 ? getTodayDate().toISO() : null,
              } as Partial<ProgressSection>

              const isSubmittedShouldBeUpdated =
                intersection(fieldsThatAffectSubmittedStatus, newRequiredFields)
                  .length > 0
              const newFieldsContainsRequiredFields =
                (
                  fieldsThatAffectSubmittedStatus.filter(fieldId => {
                    return newRequiredFields.includes(fieldId)
                  }) || []
                ).length > 0

              if (isSubmittedShouldBeUpdated) {
                progressSectionPayload.isSubmitted =
                  !newFieldsContainsRequiredFields
              }

              transaction.update(progressSectionRef, progressSectionPayload)

              const progressSectionIndex = progressSectionsMap[
                activityDoc.id
              ].findIndex(s => s.id === progressSection.id)

              progressSectionsMap[activityDoc.id].splice(
                progressSectionIndex,
                1,
                {
                  ...progressSection,
                  ...progressSectionPayload,
                },
              )
            })
          }

          activityUsersMap[activityDoc.id]?.forEach(activityUserDoc => {
            // Update the participant's completedPercentage for the overall activity
            const userId = activityUserDoc.get('userId')

            const newActivityCompletedPercentage =
              getActivityCompletionPercentage(
                progressSectionsMap[activityDoc.id].filter(
                  p => p.userId === userId,
                ),
                { id: activityDoc.id, ...activityDoc.data() } as Activity,
                sections,
              )
            transaction.update(activityUserDoc.ref, {
              completedPercentage: newActivityCompletedPercentage,
            })
          })
        }

        if (!isEmpty(versionBumpFields)) {
          // If there are fields that need a version bump, update the fields' version
          newSections = sections.map(section => {
            const newFields = section.fields.map(field => {
              if (versionBumpFields[section.id]?.includes(field.id)) {
                return {
                  ...field,
                  version: template.version,
                }
              }
              return field
            })

            return {
              ...section,
              fields: newFields,
            }
          })

          transaction.update(sectionsRef, {
            sections: newSections,
          })
        }
      })

      setPublishing(false)
      toastService.info('Successfully published template changes')
      return newSections
    } catch (err) {
      setPublishing(false)
      console.log(err)
      return toastService.error(
        'An error occurred while publishing the template changes',
      )
    }
  }

  return {
    publishActivity,
    publishing,
    noChangesMade,
  }
}
