import { CardListItem } from '@components/card-list-item.component'
import { Separator } from '@components/radix/separator'
import { analyticsUrl } from '@src/firebase-app'
import { fieldsList } from '@src/pages/workflow/fields.helper'
import { EditFieldModal } from '@src/pages/workflow/settings/edit-field-modal.component'
import { modalService } from '@utils/modal.service'
import React, { useEffect } from 'react'
import wretch from 'wretch'

export function SectionOverview({
  selectedSection,
  activity,
  onSectionChange,
  participants,
}: {
  selectedSection: ActivitySection
  activity: Activity
  onSectionChange: () => void
  participants: ActivityUser[]
}) {
  const [ratings, setRatings] = React.useState(null)

  useEffect(() => {
    if (!selectedSection) return
    wretch(
      `${analyticsUrl}/api/section/feedback/${activity.id}/${selectedSection.id}`,
    )
      .get()
      .json()
      .then(data => {
        setRatings(data)
      })
  }, [selectedSection])

  async function openEditModal(field: Field, section: ActivitySection) {
    await modalService.render(EditFieldModal, {
      field,
      section,
      orgId: activity.orgId,
    })
    onSectionChange()
  }

  return (
    <div className="flex-[7] space-y-10">
      <div>
        <h2 className="text-3xl font-semibold">Section feedback</h2>
        <div className="flex items-stretch gap-8">
          <div className="border border-grey-100 rounded-lg px-4 py-5 flex-1 max-w-[50%] space-y-4">
            <div className="flex flex-col text-3xl">
              <span>Average</span> <span className="font-semibold">Rating</span>
            </div>
            <Separator className="bg-grey-100" />
            <div className="p-2 text-5xl font-bold">
              {ratings?.averageRating
                ? Math.floor(Number(ratings?.averageRating)) + '/5'
                : 'N/A'}
            </div>
          </div>

          <div className="border border-grey-100 rounded-lg px-4 py-5 flex-1 max-w-[50%] flex flex-col justify-center gap-4">
            <div className="flex items-end justify-between">
              <div className="flex flex-col text-3xl">
                <span>Highest</span>{' '}
                <span className="font-semibold">Rating</span>
              </div>
              <div className="text-5xl font-bold">{ratings?.highestRating}</div>
            </div>
            <Separator className="bg-grey-100" />
            <div className="flex items-end justify-between">
              <div className="flex flex-col text-3xl">
                <span>Lowest</span>{' '}
                <span className="font-semibold">Rating</span>
              </div>
              <div className="text-5xl font-bold">{ratings?.lowestRating}</div>
            </div>
          </div>

          <div className="border border-grey-100 rounded-lg px-4 flex-1 max-w-[50%] py-3 flex items-stretch">
            <div className="flex-1 flex flex-col justify-center">
              <div className="flex items-end justify-between py-3 pr-3">
                <div className="flex flex-col text-3xl">
                  <span>Users with</span>{' '}
                  <span className="font-semibold">Feedback</span>
                </div>
                <div className="text-5xl font-bold">
                  {ratings?.ratingUsersCount}
                </div>
              </div>
              <Separator className="bg-grey-100" />
              <div className="flex items-end justify-between py-3 pr-3">
                <div className="flex flex-col text-3xl">
                  <span>Total</span>{' '}
                  <span className="font-semibold">Users</span>
                </div>
                <div className="text-5xl font-bold">{participants?.length}</div>
              </div>
            </div>
            <div className="flex items-center justify-center border-l border-grey-100 pl-4">
              <div className="text-5xl font-bold">
                {ratings
                  ? (Number(ratings.ratingUsersCount) / participants.length) *
                    100
                  : 0}
                %
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-5">
        <h2 className="text-3xl font-semibold">Fields</h2>
        {selectedSection?.fields.map((field: Field) => {
          const fieldType = fieldsList.find(f => f.name === field.type)
          const icon = fieldType ? fieldType.icon : ''
          return (
            <CardListItem
              onClick={() => openEditModal(field, selectedSection)}
              key={field.id}
              cardTitle={field.label}
              subTitle={field.description}
              icon={icon}
            />
          )
        })}
      </div>
    </div>
  )
}
