import wretch from 'wretch'

export function getChallengeResponsesForChallenge({
  activityId,
  field,
  iterationIndexStart,
  iterationIndexEnd,
}) {
  return wretch(
    `${process.env.USER_ANALYTICS_URL}/challenge-response/${activityId}/${field.fieldId}?start=${iterationIndexStart}&end=${iterationIndexEnd}`,
  )
    .get()
    .json(json => json)
}

export async function updateAnalyticsUserName({ userId, firstName, lastName }) {
  try {
    return await wretch(`${process.env.USER_ANALYTICS_URL}/user-name/${userId}`)
      .json({ firstName, lastName })
      .patch()
      .res()
  } catch (err) {
    console.error(err)
  }
}

export function getIterationsCompletedCount({ activityId, section }) {
  return wretch(
    `${process.env.USER_ANALYTICS_URL}/completed-count/${activityId}/${section.id}`,
  )
    .get()
    .json(json => json)
}
